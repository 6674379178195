<template>
  <meta data-runtime="yup" property="og:url" :content="url" />
  <meta data-runtime="yup" property="og:title" :content="state.title" />
  <meta data-runtime="yup" property="og:type" content="website" />
  <meta
    data-runtime="yup"
    property="og:description"
    :content="state.description"
  />
  <title>{{ state.title }}</title>
  <v-toolbar>
    <TooltipIcon
      :label="translations.home"
      :destination="{
        name: 'Planner',
        query: {
          config: 'reset',
        },
      }"
      :icon="mdiHome"
      location="bottom"
    />
    <TooltipIcon
      :label="translations.favouriteStops"
      destination="/favouriteStops"
      :icon="mdiHeart"
      location="bottom"
    />
    <ConnectionStatus />
    <TooltipIcon
      v-if="settings.logQueries"
      :label="translations.settingItems.logQueries"
      destination="/queryLog"
      :icon="mdiMathLog"
      location="bottom"
    />
    <v-btn :icon="mdiShareVariant" @click="share" />
    <v-dialog>
      <template #activator="{ props }">
        <v-btn v-bind="props" :icon="mdiCog" />
      </template>
      <v-card :title="translations.settings">
        <Settings />
      </v-card>
    </v-dialog>
  </v-toolbar>
</template>
<script lang="ts" setup>
import {
  mdiCog,
  mdiHeart,
  mdiHome,
  mdiMathLog,
  mdiShareVariant,
} from "@mdi/js";
import translations from "@/translations";
import state from "@/config/state";
import { computed } from "vue";
import { settings } from "@/scripts/settings";
const url = computed(() => window.location.toString());
[...document.querySelectorAll("meta")]
  .filter(
    (item) =>
      item.getAttribute("property")?.startsWith("og:") &&
      item.getAttribute("data-runtime") != "yup",
  )
  .forEach((item) => item.remove());
function share() {
  navigator.share({
    title: document.title,
    url: window.location.href,
  });
}
</script>
